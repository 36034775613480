import UserStoreModule from './module';
import AppStoreReadyStateEnum from '../../app/store/ready-state.enum';
import { UserPermissionSlugEnum } from '../../api/model/user/permission-slug.enum';

export function userStoreHasPermission(slug?: UserPermissionSlugEnum | string, isStrict?: boolean): boolean {
  if (
    (UserStoreModule.loadingState !== AppStoreReadyStateEnum.loaded &&
      UserStoreModule.logoutState !== AppStoreReadyStateEnum.loading) ||
    !UserStoreModule.data
  ) {
    return false;
  }

  const user = UserStoreModule.data;

  if (!user || !user.roles) return false;

  if (user.roles[0].slug === 'store.owner') return true;

  const { permissions } = user;

  const userPermissionSlugs = permissions.map((p) => p.slug.toString());

  if (!slug) return false;

  const requiredPermissions = slug.split(/[,&]/);

  if (slug.includes('&')) {
    return requiredPermissions.every((item) => userPermissionSlugs.includes(item));
  }

  if (isStrict) {
    return requiredPermissions.every((item) => userPermissionSlugs.includes(item));
  }

  return requiredPermissions.some((item) => userPermissionSlugs.includes(item));
}
