import { Route } from 'vue-router';
import { LocaleMessage } from 'vue-i18n';
import { RoutingRouteEnum } from '../../routing/route.enum';
import { zidshipAccountDataStoreModule } from '../store/account-data/module';

import NotificationTypeEnum from '../../notifications/components/type.enum';
import showNotification from '../../common/helpers/show-notification/show-notification';

export async function zidshipActiveGuard(
  to: Route,
  from: Route,
  next: Function,
  messsage: LocaleMessage,
): Promise<void> {
  const resposnse = await zidshipAccountDataStoreModule.getZidShipStatus();
  if (resposnse.data) next();
  else {
    showNotification(messsage.toString(), NotificationTypeEnum.danger);
    next({ name: RoutingRouteEnum.zidShip });
  }
}
