<template>
  <div :class="styles['sidebar-mobile-header']">
    <div :class="styles['wrapper']">
      <div :class="styles['user-box']">
        <OrderStreakNotification
          @fetchCompleted="onOrderStreakFetchCompleted"
          :class="styles['sidebar-mobile-header__order-streak']"
        />
        <StatusBarUserProfileComponent :class="styles['sidebar-mobile-header__user-status-bar']" :isSideMenu="true" />
      </div>
      <div :class="styles['actions-box']">
        <div :class="styles['actions-box-wrapper']">
          <button id="zid_notification_btn" :class="styles['notifications']">
            <ZidIcon icon="bell_outline" color="danger" size="xxs" type="solid" />
          </button>
          <button :class="styles['zid-support']" @click="openHelpCenterModal" :disabled="isSuspendedSubscription()">
            <Headphones />
          </button>
          <StatusBarLanguageSwitcherComponent :isTopNavbar="true" />
        </div>
        <div v-if="hasWalletSectionPermissions" :class="styles['wallet']">
          <StatusBarWalletComponent
            @toggleFundsModal="onToggleFundsModal"
            :disabled="isSuspendedSubscription()"
            :isSideMenu="true"
          />
        </div>
      </div>
    </div>

    <WalletStatus
      :showResponseModal="showResponseModal"
      :purchaseStatus="purchaseStatus"
      @toggleResponseModal="onToggleResponseModal"
      @toggleFundsModal="onToggleFundsModal"
    />
    <ZidWalletComponentsAddFundsLoaderModalComponent
      :showLoaderModal="showLoaderModal"
      @toggleLoaderModal="onToggleLoaderModal"
    />
    <HelpCenterComponent :isOpen="isHelpCenterModalOpen" @close="closeHelpCenterModal" />
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref } from 'vue';
import { ZidIcon } from '@zidsa/ui';
import StatusBarUserProfileComponent from '../../StatusBar/UserProfile/UserProfile';
import isSuspendedSubscription from '../../../../routing/guards/is-suspended-subscription';
import Headphones from '../../../../common/assets/icons/Headphones.vue';
import { StatusBarLanguageSwitcherComponent } from '../../StatusBar/LanguageSwitcher/LanguageSwitcher';
import { userStoreHasPermission } from '../../../../user/store/has-permission';
import { UserPermissionSlugEnum } from '../../../../api/model/user/permission-slug.enum';
import { StatusBarWalletComponent } from '../../StatusBar/ZidWallet/StatusBarWallet';
import { ZidWalletModalsStoreModule } from '../../../../zidwallet/store/modal-booleans.module';
import { PurchaseStoreModule } from '../../../../subscription/store/subscription-checkout/purchase/purchase-module';
import { ZidWalletComponentsAddFundsLoaderModalComponent } from '../../../../zidwallet/components/AddFundsModal/LoaderModal/LoaderModal';
import { WalletStatus } from '../../../../zidwallet/components/WalletStatus/WalletStatus';
import { HelpCenterComponent } from '../../../../common/components/HelpCenter/HelpCenter';
import OrderStreakNotification from '../../../../orders/components/OrderStreakNotification/OrderStreakNotification.vue';
import { useRoute } from 'vue-router/composables';

export default defineComponent({
  components: {
    OrderStreakNotification,
    ZidIcon,
    StatusBarUserProfileComponent,
    Headphones,
    StatusBarLanguageSwitcherComponent,
    StatusBarWalletComponent,
    ZidWalletComponentsAddFundsLoaderModalComponent,
    WalletStatus,
    HelpCenterComponent,
  },

  setup(_, context) {
    const route = useRoute();
    const showResponseModal = ref(false);
    const showLoaderModal = ref(false);
    const isHelpCenterModalOpen = ref(false);
    const hasWalletSectionPermissions = computed(() => userStoreHasPermission(UserPermissionSlugEnum.zidWallet));

    onMounted(async () => {
      await processPurchase();
    });

    const processPurchase = async () => {
      const purchaseId = route.query['purchase_id'];
      const tapId = route.query['tap_id'];

      if (purchaseId) {
        await PurchaseStoreModule.viewPurchaseStatusWallet(purchaseId);
        showLoaderModal.value = true;

        if (tapId) {
          await PurchaseStoreModule.confirmWalletTopup({ purchaseId, tapId }).then(() => {
            showLoaderModal.value = false;
            showResponseModal.value = true;
          });
        }
      }
    };

    const purchaseStatus = (): string | undefined => {
      return PurchaseStoreModule.data?.purchase_status.code;
    };

    const onToggleFundsModal = () => {
      ZidWalletModalsStoreModule.toggleFundsModal();
    };

    const onToggleResponseModal = (): void => {
      showResponseModal.value = !showResponseModal.value;
    };

    const onToggleLoaderModal = (): void => {
      showLoaderModal.value = !showLoaderModal;
    };

    const openHelpCenterModal = (): void => {
      isHelpCenterModalOpen.value = true;
    };

    const closeHelpCenterModal = (): void => {
      isHelpCenterModalOpen.value = false;
    };

    const onOrderStreakFetchCompleted = (): void => {
      context.emit('orderStreakFetchCompleted');
    };

    return {
      isSuspendedSubscription,
      hasWalletSectionPermissions,
      onToggleFundsModal,
      showResponseModal,
      onToggleResponseModal,
      purchaseStatus,
      showLoaderModal,
      onToggleLoaderModal,
      closeHelpCenterModal,
      openHelpCenterModal,
      isHelpCenterModalOpen,
      onOrderStreakFetchCompleted,
    };
  },
});
</script>
<style lang="scss" module="styles" src="./MobileHeaderSection.module.scss"></style>
