export const subscriptionCheckoutApiUrls = {
  getCorePackages: '/api/v1/subscriptions/core-packages/classified-by-duration',
  getCoreProducts: '/api/v1/subscriptions/core-products',
  createPurchases: '/api/v1/purchases/create',
  addPurchasesItems: '/api/v1/purchases/{purchasesId}/items',
  deletePurchasesItems: '/api/v1/purchases/{purchasesId}/items',
  setPaymentMethod: '/api/v1/purchases/{purchasesId}/payment-method',
  setIsRecurring: '/api/v1/purchases/{purchasesId}/is-recurring',
  setCoupon: '/api/v1/purchases/{purchasesId}/coupon',
  deleteCoupon: '/api/v1/purchases/{purchasesId}/coupon',
  viewPurchase: '/api/v1/purchases/{purchasesId}',
  purchasesCheckout: '/api/v1/purchases/checkout',
  purchasesBankTransfer: '/api/v1/purchases/confirm/bank-transfer',
  confirm: '/api/v1/purchases/{purchaseId}/confirm',
  checkPurchasedStatus: '/api/v1/purchases/check-purchased-status',
  confirmZidWallet: '/api/v1/purchases/{purchaseId}/confirm-zid-wallet',
  confirmWalletTopup: '/api/v1/purchases/{purchaseId}/confirm-credit-card',
  deactivateAutoRenew: '/subscriptions/recurring/deactivate',
  activateAutoRenew: '/subscriptions/recurring/activate',
  handleApplePayPurchase: '/api/v1/apple-pay/purchase/{purchaseId}/handle',
  getApplePayButtonConfig: '/api/v1/apple-pay/purchase/{purchaseId}/config',
};
