import ApiClient from '../../api/client';
import ApiHttpMethodEnum from '../../api/http-method.enum';
import { paymentOptionsApiUrls } from './urls';
import { PaymentOptionsApiBanksResponseInterface } from './banks/response.interface';
import { PaymentOptionsApiBanksTransferResponseInterface } from './banks-transfer/response.interface';
import { PaymentOptionsApiPaymentGatewaysResponseInterface } from './gateways/response.interface';
import { PaymentOptionsApiPaymentOptionsResponseInterface } from './payment-options/response.interface';
import { urlFormatParams } from '../../common/helpers/url/format-params';
import type {
  ApiBaseDataResponseObjectType,
  ApiBaseMessageResponseObjectType,
  ApiBaseResponseType,
  ApiResponseType,
} from '../../api/types';
import { BankAccountData } from '../types/account-bank-data.interface';
import { PaymentGatewayData } from '../types/payment-gateway-data.interface';
import {
  PaymentOptionsTypesStoreThirdPartyDetailsInterface,
  PaymentOptionsTypesThirdPartyProvidersWithZidPayStatusInterface,
} from '../types/other-payment-gateway.interface';
import { urlQueryFormat } from '../../common/helpers/url/query/format';

export class PaymentOptionsApiService {
  constructor(private readonly apiClient: ApiClient) {}

  public async getPaymentOptions(): Promise<PaymentOptionsApiPaymentOptionsResponseInterface> {
    const response = await this.apiClient.request<PaymentOptionsApiPaymentOptionsResponseInterface>(
      ApiHttpMethodEnum.get,
      paymentOptionsApiUrls.paymentOptions,
    );

    return response.data;
  }

  public async getBanks(): Promise<PaymentOptionsApiBanksResponseInterface> {
    const response = await this.apiClient.request<PaymentOptionsApiBanksResponseInterface>(
      ApiHttpMethodEnum.get,
      paymentOptionsApiUrls.banks,
    );

    return response.data;
  }

  public async getBanksAccounts(): Promise<ApiResponseType<PaymentOptionsApiBanksTransferResponseInterface>> {
    const response = await this.apiClient.request<PaymentOptionsApiBanksTransferResponseInterface>(
      ApiHttpMethodEnum.get,
      paymentOptionsApiUrls.banksAccounts,
      { cacheable: true },
    );

    return response;
  }

  public async getThirdPartyProviders(
    lang?: string,
  ): Promise<ApiBaseDataResponseObjectType<PaymentOptionsTypesThirdPartyProvidersWithZidPayStatusInterface>> {
    const params = urlQueryFormat({
      lang: lang,
    });
    const response = this.apiClient.request<
      ApiBaseDataResponseObjectType<PaymentOptionsTypesThirdPartyProvidersWithZidPayStatusInterface>
    >(ApiHttpMethodEnum.get, paymentOptionsApiUrls.thirdPartyProviders, { params: params });

    return (await response).data;
  }

  public async getThirdPartyProviderActivationUrl(
    lang?: string,
    thirdPartyProviderId?: string,
  ): Promise<ApiBaseDataResponseObjectType<any>> {
    const params = urlQueryFormat({
      lang: lang,
      ThirdPartyProviderId: thirdPartyProviderId,
    });
    const response = this.apiClient.request<ApiBaseDataResponseObjectType<any>>(
      ApiHttpMethodEnum.get,
      paymentOptionsApiUrls.thirdPartyProviderActivationUrl,
      { params: params },
    );
    return (await response).data;
  }

  public async getStoreThirdPartyDetails(): Promise<
    ApiBaseDataResponseObjectType<PaymentOptionsTypesStoreThirdPartyDetailsInterface>
  > {
    const response = this.apiClient.request<
      ApiBaseDataResponseObjectType<PaymentOptionsTypesStoreThirdPartyDetailsInterface>
    >(ApiHttpMethodEnum.get, paymentOptionsApiUrls.thirdPartyDetailsByStoreId);
    return (await response).data;
  }

  public async activeAllThirdPartyNetworks(): Promise<ApiBaseDataResponseObjectType> {
    const response = this.apiClient.request<ApiBaseDataResponseObjectType>(
      ApiHttpMethodEnum.post,
      paymentOptionsApiUrls.activeAllThirdPartyNetworks,
    );
    return (await response).data;
  }

  public async disableAllThirdPartyNetworks(): Promise<ApiBaseDataResponseObjectType> {
    const response = this.apiClient.request<ApiBaseDataResponseObjectType>(
      ApiHttpMethodEnum.post,
      paymentOptionsApiUrls.disableAllThirdPartyNetworks,
    );
    return (await response).data;
  }

  public async getSupportedNetworks(): Promise<ApiBaseDataResponseObjectType<any>> {
    const response = this.apiClient.request<ApiBaseDataResponseObjectType<any>>(
      ApiHttpMethodEnum.get,
      paymentOptionsApiUrls.supportedNetworks,
    );
    return (await response).data;
  }

  public async getPaymentGateways(): Promise<ApiResponseType<PaymentOptionsApiPaymentGatewaysResponseInterface>> {
    const response = await this.apiClient.request<PaymentOptionsApiPaymentGatewaysResponseInterface>(
      ApiHttpMethodEnum.get,
      paymentOptionsApiUrls.gateways,
      { cacheable: true },
    );

    return response;
  }

  public async updatePaymentGateway(
    paymentGatewayCode: string,
    data: PaymentGatewayData,
  ): Promise<ApiBaseDataResponseObjectType<PaymentGatewayData>> {
    const url = urlFormatParams(paymentOptionsApiUrls.updateGateway, {
      paymentGatewayCode,
    });

    const response = await this.apiClient.request<ApiBaseDataResponseObjectType<PaymentGatewayData>>(
      ApiHttpMethodEnum.post,
      url,
      { data },
    );
    return response.data;
  }

  public async addBankAccount(data: BankAccountData): Promise<ApiBaseDataResponseObjectType<BankAccountData>> {
    const response = await this.apiClient.request<ApiBaseDataResponseObjectType<BankAccountData>>(
      ApiHttpMethodEnum.post,
      paymentOptionsApiUrls.addBankAccount,
      { data },
    );
    return response.data;
  }

  public async editBankAccount(
    bankAccountId: string,
    data: BankAccountData,
  ): Promise<ApiBaseDataResponseObjectType<BankAccountData>> {
    const url = urlFormatParams(paymentOptionsApiUrls.editBankAccount, {
      bankAccountId,
    });
    // eslint-disable-next-line @typescript-eslint/camelcase
    data = { ...data, bank_id: data.bank_id };
    const response = await this.apiClient.request<ApiBaseDataResponseObjectType<BankAccountData>>(
      ApiHttpMethodEnum.post,
      url,
      { data },
    );
    return response.data;
  }

  public async toggleBankTransferMethodStatus(): Promise<ApiBaseMessageResponseObjectType> {
    const response = await this.apiClient.request<ApiBaseMessageResponseObjectType>(
      ApiHttpMethodEnum.post,
      paymentOptionsApiUrls.banksAccountsStatus,
    );
    return response.data;
  }

  public async deleteBankAccount(bankAccountId: string): Promise<ApiBaseResponseType> {
    const url = urlFormatParams(paymentOptionsApiUrls.deletebankAccount, {
      bankAccountId,
    });
    const response = await this.apiClient.request<ApiBaseResponseType>(ApiHttpMethodEnum.delete, url);

    return response.data;
  }
}
