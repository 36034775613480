export const orderNotificationsApiUrls = {
  balance: '/api/v1/orders/notifications/data',
  statuses: '/api/v1/orders/notifications/statuses',
  message: '/api/v1/orders/notifications/{status}',
  resetSingleStatus: '/api/v1/orders/notifications/{status}',
  orderNotifications: '/api/v1/orders/notifications',
  orderNotificationsDefualt: '/api/v1/orders/notifications/defaults',
  senderIdServices: '/api/v1/orders/notifications',
  lastSms: '/api/v1/orders/notifications',
  orderStreak: '/api/v1/orders/order-streak',
  orderStreakImageToShare: '/api/v1/orders/orders-streak-share',
};
