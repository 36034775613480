import { Action, getModule, Module, VuexModule, Mutation } from 'vuex-module-decorators';
import appStoreFactory from '../../../app/store/factory';
import { SettingsApiAccountServiceFactory } from '../../api/account/service-factory';
import AppStoreReadyStateEnum from '../../../app/store/ready-state.enum';
import SettingsTypesAccountSettingsInterface from '../../types/account-settings.interface';
import SettingsTypesAccountPasswordInterface from '../../types/account-password.interface';
import initialAccountSettingsData from './initial-account-settings.data';
import { SettingsApiAccountSettingsResponseInterface } from '../../api/account/response.interface';
import ApiModelFeatureFlagsInterface from '../../../api/model/feature-flags.interface';
import SettingsTypesModeratorInterface from '../../types/moderator.interface';
import type { SettingsTypesAccountSettingsType } from '../../types/account-settings.type';
import Catch from '../../../common/decorators/catch-error';
import { SettingAccountSessionLogoutEnums } from '../../enums/session-logout-strictness.enum';

const service = SettingsApiAccountServiceFactory();

@Module({
  dynamic: true,
  name: 'AccountSettingsModule',
  store: appStoreFactory(),
  namespaced: true,
})
class AccountSettingsModule extends VuexModule {
  public loadingState: AppStoreReadyStateEnum = AppStoreReadyStateEnum.pending;
  public loadingPasswordState: AppStoreReadyStateEnum = AppStoreReadyStateEnum.pending;
  public accountInfo: SettingsTypesAccountSettingsInterface = initialAccountSettingsData;
  public featureFlags: ApiModelFeatureFlagsInterface | null = null;
  public moderatorData = {} as SettingsTypesModeratorInterface;
  public moderatorDataChanged = false;
  public passwordChanged = false;
  public passwordFaildMessage = '';
  public accountSettings: SettingsTypesAccountSettingsType | null = null;
  public loadingAccountSettingsState: AppStoreReadyStateEnum = AppStoreReadyStateEnum.idle;
  public serverSyncedAccountSettings: SettingsTypesAccountSettingsType | null = null;

  @Action({ rawError: true })
  public async updateAccountInfo(
    data: SettingsTypesAccountSettingsInterface,
  ): Promise<SettingsApiAccountSettingsResponseInterface> {
    const response = await service.updateAccountSettings(data);
    return response;
  }

  @Action
  @Catch({ onError: (_, ctx) => ctx.onupdatePaaswordError(_) })
  public async updateAccountPassword(data: SettingsTypesAccountPasswordInterface): Promise<void> {
    this.updateAccountPasswordLoading();
    await service.updateAccountPassword(data);
    this.updateAccountPasswordSuccess();
  }

  @Action
  public updateAccountPasswordSuccess(): void {
    this.UPDATING_ACCOUNT_PASSWORD_SUCCESS();
  }

  @Action
  public updateAccountPasswordLoading(): void {
    this.UPDATING_ACCOUNT_PASSWORD();
  }

  @Action
  public onupdatePaaswordError(error: any): void {
    this.ON_UPDATE_PASSWORD_ERROR(error);
  }

  @Mutation
  private ON_UPDATE_PASSWORD_ERROR(data: any): void {
    this.loadingPasswordState = AppStoreReadyStateEnum.loaded;
    if (data.response.status == 400) {
      this.passwordFaildMessage = data.response.data.message;
    }
  }

  @Mutation
  private UPDATING_ACCOUNT_PASSWORD(): void {
    this.loadingPasswordState = AppStoreReadyStateEnum.loading;
  }

  @Mutation
  private UPDATING_ACCOUNT_PASSWORD_SUCCESS(): void {
    this.loadingPasswordState = AppStoreReadyStateEnum.loaded;
    this.passwordFaildMessage = '';
  }

  @Action
  public setAccountInfo(data: SettingsTypesAccountSettingsInterface): void {
    this.SET_ACCOUNT_INFO(data);
  }

  @Mutation
  private FETCH_MODERATOR_SUCCESS(data: SettingsTypesModeratorInterface): void {
    this.loadingState = AppStoreReadyStateEnum.loaded;
    this.moderatorData = data;
  }

  @Mutation
  private FETCH_MODERATOR(): void {
    this.loadingState = AppStoreReadyStateEnum.loading;
    this.moderatorData = {} as SettingsTypesModeratorInterface;
  }

  @Mutation
  private SET_ACCOUNT_INFO(data: SettingsTypesAccountSettingsInterface): void {
    this.accountInfo = data;
  }

  @Mutation
  public SET_ACCOUNT_SETTINGS(data: SettingsTypesAccountSettingsType | null): void {
    this.accountSettings = data;
  }

  @Mutation
  public SET_LOADING_ACCOUNT_SETTINGS_STATE(state: AppStoreReadyStateEnum): void {
    this.loadingAccountSettingsState = state;
  }

  @Mutation
  public SET_SERVER_SYNCED_ACCOUNT_SETTINGS(data: SettingsTypesAccountSettingsType | null): void {
    this.serverSyncedAccountSettings = data;
  }

  @Action({ rawError: true })
  public async getFeatureFlagInfo(): Promise<void> {
    const response = await service.getFeatureFlags();
    this.SET_FEATURE_FLAGS(response?.data);
  }

  @Mutation
  private SET_FEATURE_FLAGS(response: ApiModelFeatureFlagsInterface): void {
    this.featureFlags = response;
  }

  @Action({ rawError: true })
  @Catch({ onError: (_, ctx) => ctx.FETCH_MODERATOR_SUCCESS({}) })
  public async getModeratorData(id: string): Promise<void> {
    this.FETCH_MODERATOR();
    const response = await service.getModerator(id);
    this.SET_MODERATOR_DATA(response.data);
    this.FETCH_MODERATOR_SUCCESS(response.data);
  }

  @Action({ rawError: true })
  public async setPasscode(passcode: number | string): Promise<void> {
    this.SET_MODERATOR_DATA({ ...this.moderatorData, passcode: String(passcode) });
  }

  @Mutation
  private SET_MODERATOR_DATA(data: SettingsTypesModeratorInterface): void {
    this.moderatorData = Object.assign({}, this.moderatorData, data);
  }

  @Mutation
  private SET_MODERATOR_DATA_BOOL(value: boolean): void {
    this.moderatorDataChanged = value;
  }

  @Action
  public async setModeratorDataBool(value: boolean): Promise<void> {
    this.SET_MODERATOR_DATA_BOOL(value);
  }

  @Mutation
  private SET_PASSWORD_CHANGED_BOOL(value: boolean): void {
    this.passwordChanged = value;
  }

  @Action
  public async setPasswordChangedBool(value: boolean): Promise<void> {
    this.SET_PASSWORD_CHANGED_BOOL(value);
  }

  @Action({ rawError: true })
  public async updatePasscode(id: string): Promise<void> {
    await service.updateModerator(id, this.moderatorData);
  }

  @Action
  @Catch()
  public async updateLogoutStrictness(strictness: SettingAccountSessionLogoutEnums | null): Promise<void> {
    if (!strictness || strictness === this.serverSyncedAccountSettings?.md_security_strictness) return;

    await service.updateLogoutControl({ strictness: strictness });
    this.SET_ACCOUNT_SETTINGS({
      ...(this.accountSettings as SettingsTypesAccountSettingsType),
      ['md_security_strictness']: strictness,
    });
    this.SET_SERVER_SYNCED_ACCOUNT_SETTINGS({
      ...(this.accountSettings as SettingsTypesAccountSettingsType),
      ['md_security_strictness']: strictness,
    });
  }

  @Action({ rawError: true })
  public async updateAccountInfoRegistration(
    data: SettingsTypesAccountSettingsInterface,
  ): Promise<SettingsApiAccountSettingsResponseInterface> {
    const response = await service.updateAccountSettingsRegistration(data);
    return response;
  }

  @Action
  public fetchAccountSettings(): Promise<void> {
    this.SET_LOADING_ACCOUNT_SETTINGS_STATE(AppStoreReadyStateEnum.loading);
    return service
      .getAccountSettings()
      .then((response) => {
        this.SET_ACCOUNT_SETTINGS(response.data);
        this.SET_SERVER_SYNCED_ACCOUNT_SETTINGS(response.data);
      })
      .finally(() => {
        this.SET_LOADING_ACCOUNT_SETTINGS_STATE(AppStoreReadyStateEnum.loaded);
      });
  }
}

export const AccountSettingsStoreModule = getModule(AccountSettingsModule);
