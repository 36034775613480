import { RoutingRouteEnum } from '../../../routing/route.enum';

// The permitted pages that don't require any permission to access
export const UnrestrictedPages = [
  RoutingRouteEnum.analyticsReports,
  RoutingRouteEnum.liveAnalytics,
  RoutingRouteEnum.analyticsV2,
  RoutingRouteEnum.home,
  RoutingRouteEnum.appMarket,
  RoutingRouteEnum.appMarketBrowseApps,
  RoutingRouteEnum.appMarketDetails,
  RoutingRouteEnum.appMarketMyApps,
  RoutingRouteEnum.appMarketAppsCollection,
  RoutingRouteEnum.themeMarketHome,
  RoutingRouteEnum.themeMarketDetails,
  RoutingRouteEnum.themeMarketBrowse,
  RoutingRouteEnum.mobileAppLandingPage,
  RoutingRouteEnum.settingsAccount,
];
