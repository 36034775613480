// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".d755584b [class~=zid-confirm-modal-v2__title]{font-size:var(--zid-font-size--base) !important;color:var(--zid-colors-neutrals-600) !important}.b1a995cc{margin-inline-end:var(--zid-spacing-0) !important}", "",{"version":3,"sources":["webpack://./src/common/components/PermissionDeniedModal/PermissionDeniedModal.vue"],"names":[],"mappings":"AAEE,+CACE,+CAAA,CACA,+CAAA,CAGF,UACE,iDAAA","sourcesContent":["\n.permission_modal {\n  [class~='zid-confirm-modal-v2__title'] {\n    font-size: var(--zid-font-size--base) !important;\n    color: var(--zid-colors-neutrals-600) !important;\n  }\n\n  &__close {\n    margin-inline-end: var(--zid-spacing-0) !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"permission_modal": "d755584b",
	"permission_modal__close": "b1a995cc"
};
export default ___CSS_LOADER_EXPORT___;
