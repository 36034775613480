import Vue from 'vue';
import { userStoreHasPermission } from '../../user/store/has-permission';
import { UserPermissionSlugEnum } from '../../api/model/user/permission-slug.enum';

export const ZidWalletPermissionsMixin = Vue.extend({
  computed: {
    hasViewWalletPermission(): boolean {
      return userStoreHasPermission(UserPermissionSlugEnum.zidWallet);
    },
    hasManageWalletPermission(): boolean {
      return userStoreHasPermission(UserPermissionSlugEnum.zidWalletManage);
    },
    hasWalletExportPermission(): boolean {
      return userStoreHasPermission(UserPermissionSlugEnum.zidWalletExport);
    },
  },
});
