import Vue, { CreateElement, VNode } from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { ZidIcon, ZidLoader, ZidTooltip } from '@zidsa/ui';
import { I18nMessages } from '../../../../i18n/messages';
import { ZidWalletStoreModule } from '../../../../zidwallet/store/wallet.module';
import AppStoreReadyStateEnum from '../../../store/ready-state.enum';
import ApiModelUserInterface from '../../../../api/model/user.interface';
import UserStoreModule from '../../../../user/store/module';
import { WalletComponentAddFundsModal } from '../../../../zidwallet/components/AddFundsModal/AddFundsModal';
import { VerifyWalletModalComponent } from '../../../../zidwallet/components/VerifyWallet/VerifyWalletModal';
import { WalletIcon } from './customIconsComponents/WalletIcon';
import { RoutingRouteEnum } from '../../../../routing/route.enum';

//Styles & Assets
import styles from './StatusBarWallet.scss';
import { UserPermissionSlugEnum } from '../../../../api/model/user/permission-slug.enum';
import { userStoreHasPermission } from '../../../../user/store/has-permission';
import { PermissionDeniedModalModule } from '../../../store/permission-denied-module';

@Component
export class StatusBarWalletComponent extends Vue {
  @Prop()
  private readonly disabled!: boolean;

  @Prop({ default: false })
  private readonly isSideMenu!: boolean;

  private isWalletHovered = false;

  private walletWidth = 'auto';

  get user(): ApiModelUserInterface | null {
    return UserStoreModule.data;
  }

  get balance(): number {
    return ZidWalletStoreModule.data.totalBalance ?? 0;
  }

  get lowBalanceThreshold(): number {
    return ZidWalletStoreModule.data.lowBalanceThreshold ?? 500;
  }

  get isLoading(): boolean {
    return ZidWalletStoreModule.loadingState === AppStoreReadyStateEnum.loading;
  }

  created(): void {
    ZidWalletStoreModule.fetchWallet().then(() => {
      this.handleInitialWalletWidth();
    });
  }

  get isBalanceLow(): boolean {
    if (UserStoreModule.loadingState !== AppStoreReadyStateEnum.loaded || !this.user) {
      return false;
    }

    return this.balance < this.lowBalanceThreshold;
  }

  render(h: CreateElement): VNode {
    return (
      <div class={[styles.wallet__button, { [styles.wallet__button_hovered]: this.isWalletHovered && !this.disabled }]}>
        <div onMouseenter={this.handleMouseEnter} onMouseleave={this.handleMouseLeave}>
          <button disabled={this.disabled} class={styles.wallet__dropdown__button}>
            <WalletIcon />
            {this.isLoading ? (
              <ZidLoader size={'small'} />
            ) : (
              <div class={styles['wallet__button__container']} style={{ width: this.walletWidth }}>
                {this.isWalletHovered && !this.disabled ? (
                  <div class={styles.wallet_hover_wrapper}>
                    <button class={styles.wallet_hover_wrapper__managebutton} onClick={this.onManageWalletClicked}>
                      <span class={styles.wallet_hover_wrapper__text}>
                        {this.$t(I18nMessages['wallet.manage.wallet'])}
                      </span>
                    </button>
                    {this.renderWalletButtonContent(h)}
                  </div>
                ) : (
                  <span class={styles.wallet__button__text} ref='balanceRef'>
                    {this.balance.toLocaleString()} &nbsp;
                    {this.$t(I18nMessages['zidship.sar'])}
                  </span>
                )}
              </div>
            )}
          </button>
        </div>

        <WalletComponentAddFundsModal />

        <VerifyWalletModalComponent />
      </div>
    );
  }

  private renderWalletButtonContent(h: CreateElement): VNode {
    const button = (
      <button class={styles.wallet_hover_wrapper__fundsbutton} onClick={this.onToggleFundsModal}>
        <ZidIcon icon='add_circle_outline' size='xxs' />
      </button>
    );
    return (
      <div>
        {this.isSideMenu ? (
          button
        ) : (
          <ZidTooltip contentWidth='200px'>
            {button}
            <template slot='tooltip'>{this.$t(I18nMessages['zidship.main_layout.dropdown_label'])}</template>
          </ZidTooltip>
        )}
      </div>
    );
  }

  private onToggleFundsModal(): void {
    if (!this.hasWalletTopUpPermissions) {
      PermissionDeniedModalModule.showPermissionDeniedModal();
      return;
    }

    this.$emit('toggleFundsModal');
  }

  private onManageWalletClicked(): void {
    this.$router.push({ name: RoutingRouteEnum.zidWallet });
  }

  private handleMouseEnter(): void {
    this.isWalletHovered = true;
  }

  private handleMouseLeave(): void {
    this.isWalletHovered = false;
  }

  private get hasWalletTopUpPermissions(): boolean {
    return userStoreHasPermission(UserPermissionSlugEnum.zidWalletManage);
  }

  private handleInitialWalletWidth(): void {
    const balanceRef = this.$refs.balanceRef as HTMLElement;
    if (balanceRef) {
      this.walletWidth = balanceRef.offsetWidth + 'px';
    }
  }
}
