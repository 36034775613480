import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import appStoreFactory from './factory';

@Module({
  dynamic: true,
  name: 'permission-denied',
  store: appStoreFactory(),
  namespaced: true,
})
class PermissionDeniedModal extends VuexModule {
  public title: string | null = null;
  public isVisible = false;

  @Mutation
  private SET_PERMISSION_DENIED_TITLE(title: string): void {
    this.title = title;
  }

  @Mutation
  private SET_VISIBLE_STATE(value: boolean): void {
    this.isVisible = value;
  }

  @Mutation
  private RESET_TITLE(): void {
    this.title = null;
  }

  @Action
  public showPermissionDeniedModal(title?: string): void {
    console.log('Working showPermissionDeniedModal');
    if (title && title.trim.length > 1) this.SET_PERMISSION_DENIED_TITLE(title);
    this.SET_VISIBLE_STATE(true);
  }

  @Action
  public closePermissionDeniedModal(): void {
    this.SET_VISIBLE_STATE(false);
    this.RESET_TITLE();
  }
}

export const PermissionDeniedModalModule = getModule(PermissionDeniedModal);
