import ApiClient from '../../api/client';
import ApiHttpMethodEnum from '../../api/http-method.enum';
import { ProductsApiResponseInterface } from './response.interface';
import { ProductsApiFiltersInterface } from './filters.interface';
import { urlQueryFormat } from '../../common/helpers/url/query/format';
import { productsApiFiltersToSearchParams } from './filters-to-search-params';
import { productsApiUrls } from './urls';
import {
  ApiBaseDataResponseObjectType,
  ApiBaseMessageResponseObjectType,
  ApiBaseResponseObjectType,
  ApiBaseResponseType,
  ApiResponseType,
} from '../../api/types';
import { ProductsProductApiResponseInterface } from './product/response.interface';
import { ApiModelProductInterface } from '../../api/model/product.interface';
import {
  GroupedProductTypesGroupedProductCreateDraftRequestInterface,
  ProductsTypesGetOptionsFiltersType,
  ProductsTypesProductCreateDraftRequestInterface,
  ProductsTypesProductOptionType,
  ProductVoucherItemInterface,
  ProductVoucherItemPayloadInterface,
} from '../types';
import { ApiModelProductImageInterface } from '../../api/model/product/image.interface';
import { ApiModelInventoryAddressInterfaceV2 } from '../../api/model/inventory-address.interface';
import Catch from '../../common/decorators/catch-error';
import { AttributePresetsInterface } from './attribute/attribute.interface';
import { ApiModelProductAttributeInterface } from '../../api/model/product/attribute.interface';
import { ApiPaginatedDataInterface } from '../../api/paginated-data.interface';
import { I18nLocalizedStringMapType } from '../../i18n/types';
import { ApiModelProductCustomInputInterface } from '../../api/model/product/custom-input.interface';
import { ApiModelProductCustomOptionInterface } from '../../api/model/product/custom-option.interface';
import { i18nGetInitialLocalizedStringMap } from '../../i18n/get-initial-localized-string-map';

import { ApiModelProductBadgesInterface } from '../../api/model/product.badges.interface';

import { ProductVouchersApiFiltersInterface } from './filters-vouchers.interface';
import { urlFormatParams } from '../../common/helpers/url/format-params';
import { ApiSimilarProductsInterface } from './similar-products/similars.interface';
import { PrintingBarcodeApiRequestInterface } from './printing-bracode/request.interface';
import { ApiProductStockHistoryInterface } from './products-stock-history/history.interface';
import axios from 'axios';
import {
  DownloadableLibraryItemInterface,
  ProductDownloadableInterface,
} from '../store/downloadable/product.downloadable.interface';
import { ApiModelProductVideoInterface } from '../../api/model/product/video.interface';
import { ApiModelLinkedProductsCountInterface } from '../../api/model/product.linked-product-count.interface';

export class ProductsApiService {
  constructor(private readonly apiClient: ApiClient) {}

  public async getProducts(
    filters: ProductsApiFiltersInterface,
    cacheable = true,
    controller?: AbortController | null,
  ): Promise<ApiResponseType<ApiBaseDataResponseObjectType<ProductsApiResponseInterface>>> {
    const params = urlQueryFormat(productsApiFiltersToSearchParams(filters));

    const response = await this.apiClient.request<ApiBaseDataResponseObjectType<ProductsApiResponseInterface>>(
      ApiHttpMethodEnum.get,
      productsApiUrls.products,
      {
        params,
        cacheable,
        signal: controller?.signal,
      },
    );

    return response;
  }
  public async getPropducImageLabels(): Promise<ApiBaseDataResponseObjectType<ApiModelProductBadgesInterface[]>> {
    const response = await this.apiClient.request<ApiBaseDataResponseObjectType<ApiModelProductBadgesInterface[]>>(
      ApiHttpMethodEnum.get,
      productsApiUrls.productsBadges,
    );
    return response.data;
  }

  public async getLinkedProductsCount(
    productId: string,
  ): Promise<ApiBaseDataResponseObjectType<ApiModelLinkedProductsCountInterface>> {
    const response = await this.apiClient.request<ApiBaseDataResponseObjectType<ApiModelLinkedProductsCountInterface>>(
      ApiHttpMethodEnum.get,
      productsApiUrls.linkedProductsCount.replace('{productId}', productId),
    );
    return response.data;
  }

  public async productsDelete(productIds: Array<string>): Promise<ApiBaseResponseObjectType> {
    const params = urlQueryFormat({
      id: productIds,
    });

    return this.apiClient
      .request<ApiBaseResponseObjectType>(ApiHttpMethodEnum.delete, productsApiUrls.productsDelete, {
        params,
      })
      .then((response) => response.data);
  }

  public async getProduct(productId: string): Promise<ProductsProductApiResponseInterface> {
    const url = productsApiUrls.product.replace('{productId}', productId);

    const response = await this.apiClient.request<ProductsProductApiResponseInterface>(ApiHttpMethodEnum.get, url);

    // console.log(response.data);
    return response.data;
  }

  public async duplicateProduct(productId: string): Promise<ApiBaseResponseType> {
    const url = productsApiUrls.duplicate.replace('{productId}', productId);

    return this.apiClient.request<ApiBaseResponseType>(ApiHttpMethodEnum.post, url, {}).then((result) => result.data);
  }

  public async bulkDuplicate(products: Array<{ id: string; name: string }>): Promise<ApiBaseResponseType> {
    const url = productsApiUrls.bulkDuplicate;

    return this.apiClient
      .request<ApiBaseResponseType>(ApiHttpMethodEnum.post, url, { data: products })
      .then((result) => result.data);
  }

  public async exportAllProducts(email: string): Promise<ApiBaseResponseType> {
    const url = productsApiUrls.export;

    const params = urlQueryFormat({
      email: email,
    });

    return this.apiClient
      .request<ApiBaseResponseType>(ApiHttpMethodEnum.post, url, {
        params,
      })
      .then((result) => result.data);
  }

  public async exportSelectedProducts(email: string, productIds?: string): Promise<ApiBaseResponseType> {
    const url = productsApiUrls.export;

    const params = urlQueryFormat({
      email,
      ['product_ids']: productIds,
    });

    return this.apiClient
      .request<ApiBaseResponseType>(ApiHttpMethodEnum.post, url, {
        params,
      })
      .then((result) => result.data);
  }

  @Catch()
  public async importProducts(data: FormData): Promise<ApiBaseResponseType> {
    const response = await this.apiClient.request<ApiBaseResponseType>(ApiHttpMethodEnum.post, productsApiUrls.import, {
      data,
    });

    return response.data;
  }

  public async updateBulkProducts(data: Array<ApiModelProductInterface>): Promise<ApiBaseResponseType> {
    const response = await this.apiClient.request<ApiBaseResponseType>(
      ApiHttpMethodEnum.patch,
      productsApiUrls.productsBulkUpdate,
      {
        data,
      },
    );

    return response.data;
  }

  public async deleteStock(productId: string, stockId: string): Promise<ApiBaseResponseType> {
    const url = urlFormatParams(productsApiUrls.deleteStock, {
      productId,
      stockId,
    });

    const response = await this.apiClient.request<ApiBaseResponseType>(ApiHttpMethodEnum.post, url);
    return response.data;
  }

  public async getJob(): Promise<ApiBaseResponseType> {
    const url = productsApiUrls.importJob;

    return this.apiClient.request<ApiBaseResponseType>(ApiHttpMethodEnum.get, url).then((result) => result.data);
  }

  public async createProductDraft(
    data: ProductsTypesProductCreateDraftRequestInterface,
  ): Promise<ApiBaseDataResponseObjectType<ApiModelProductInterface> & { message: string }> {
    const response = await this.apiClient.request<
      ApiBaseDataResponseObjectType<ApiModelProductInterface> & { message: string }
    >(ApiHttpMethodEnum.post, productsApiUrls.createDraft, {
      data,
    });

    return response.data;
  }

  public async createGroupedProductDraft(
    data: GroupedProductTypesGroupedProductCreateDraftRequestInterface,
  ): Promise<ApiBaseDataResponseObjectType<ApiModelProductInterface> & { message: string }> {
    const response = await this.apiClient.request<
      ApiBaseDataResponseObjectType<ApiModelProductInterface> & { message: string }
    >(ApiHttpMethodEnum.post, productsApiUrls.createDraft, {
      data,
    });

    return response.data;
  }

  @Catch()
  public async uploadProductImage(
    productId: string,
    file: File,
  ): Promise<ApiBaseDataResponseObjectType<ApiModelProductImageInterface> & { message: string }> {
    const url = productsApiUrls.uploadProductImage.replace('{productId}', productId);

    const data = new FormData();
    data.append('file', file);
    const response = await this.apiClient.request<
      ApiBaseDataResponseObjectType<ApiModelProductImageInterface> & { message: string }
    >(ApiHttpMethodEnum.post, url, { data });

    return response.data;
  }

  @Catch()
  public async addProductVideoUrl(
    productId: string,
    data: Partial<ApiModelProductVideoInterface>,
  ): Promise<ApiBaseDataResponseObjectType<ApiModelProductVideoInterface> & { message: string }> {
    const url = productsApiUrls.addProductVideoUrl.replace('{productId}', productId);

    const response = await this.apiClient.request<
      ApiBaseDataResponseObjectType<ApiModelProductVideoInterface> & { message: string }
    >(ApiHttpMethodEnum.post, url, { data });

    return response.data;
  }

  @Catch()
  public async editVideoAlt(
    productId: string,
    videoId: string,
    altText: I18nLocalizedStringMapType,
  ): Promise<ApiBaseMessageResponseObjectType> {
    const url = productsApiUrls.updateProductVideo.replace('{productId}', productId).replace('{videoId}', videoId);

    const response = await this.apiClient.request<ApiBaseMessageResponseObjectType>(ApiHttpMethodEnum.post, url, {
      data: {
        ['alt_text']: altText,
      },
    });

    return response.data;
  }

  @Catch()
  public async sortProductVideo(
    productId: string,
    videoId: string,
    order: number,
  ): Promise<ApiBaseMessageResponseObjectType> {
    const url = productsApiUrls.updateProductVideo.replace('{productId}', productId).replace('{videoId}', videoId);

    const response = await this.apiClient.request<ApiBaseMessageResponseObjectType>(ApiHttpMethodEnum.post, url, {
      data: {
        ['display_order']: order,
      },
    });

    return response.data;
  }

  @Catch()
  public async deleteProductVideo(productId: string, videoId: string): Promise<ApiBaseMessageResponseObjectType> {
    const url = productsApiUrls.deleteProductVideo.replace('{productId}', productId).replace('{videoId}', videoId);

    const response = await this.apiClient.request<ApiBaseMessageResponseObjectType>(ApiHttpMethodEnum.post, url);

    return response.data;
  }

  public async getLocations(): Promise<ApiBaseDataResponseObjectType<ApiModelInventoryAddressInterfaceV2[]>> {
    const response = await this.apiClient.request<ApiBaseDataResponseObjectType<ApiModelInventoryAddressInterfaceV2[]>>(
      ApiHttpMethodEnum.get,
      productsApiUrls.locations,
    );

    return response.data;
  }

  @Catch()
  public async updateProduct(
    productId: string,
    data: ApiModelProductInterface,
  ): Promise<ProductsProductApiResponseInterface & { message: string }> {
    const url = productsApiUrls.updateProduct.replace('{productId}', productId);

    const response = await this.apiClient.request<ProductsProductApiResponseInterface & { message: string }>(
      ApiHttpMethodEnum.post,
      url,
      {
        data,
      },
    );

    return response.data;
  }

  @Catch()
  public async deleteProductImage(productId: string, imageId: string): Promise<ApiBaseMessageResponseObjectType> {
    const url = productsApiUrls.deleteProductImage.replace('{productId}', productId).replace('{imageId}', imageId);

    const response = await this.apiClient.request<ApiBaseMessageResponseObjectType>(ApiHttpMethodEnum.post, url);

    return response.data;
  }

  @Catch()
  public async editImageAlt(
    productId: string,
    imageId: string,
    altText: I18nLocalizedStringMapType,
  ): Promise<ApiBaseMessageResponseObjectType> {
    const url = productsApiUrls.editImageAlt.replace('{productId}', productId).replace('{imageId}', imageId);
    const data = {
      ['alt_text']: altText,
    };

    const response = await this.apiClient.request<ApiBaseMessageResponseObjectType>(ApiHttpMethodEnum.post, url, {
      data,
    });

    return response.data;
  }

  @Catch()
  public async addProductCategory(productId: string, categoryId: string): Promise<ApiBaseResponseObjectType> {
    const url = productsApiUrls.addProductCategory.replace('{productId}', productId);

    const response = await this.apiClient.request<ApiBaseResponseObjectType>(ApiHttpMethodEnum.post, url, {
      params: {
        categoryId,
      },
    });

    return response.data;
  }

  @Catch()
  public async deleteDonationCategory(productId: string, categoryId: string): Promise<ApiBaseResponseObjectType> {
    const url = productsApiUrls.deleteProductCategory.replace('{productId}', productId);

    const response = await this.apiClient.request<ApiBaseResponseObjectType>(ApiHttpMethodEnum.post, url, {
      params: {
        categoryId,
      },
    });

    return response.data;
  }

  @Catch()
  public async deleteProductFiltrationCriteria(
    attributeId: string,
    criteriaId: string,
  ): Promise<ApiBaseResponseObjectType> {
    const url = productsApiUrls.deleteFiltrationCriteria
      .replace('{attributeId}', attributeId)
      .replace('{id}', criteriaId);

    const response = await this.apiClient.request<ApiBaseResponseObjectType>(ApiHttpMethodEnum.delete, url);

    return response.data;
  }

  @Catch()
  public async addExtraAttributesToProduct(
    attributeId: string,
    payload: { product: string } & Partial<AttributePresetsInterface>,
  ): Promise<ApiBaseDataResponseObjectType<ApiModelProductAttributeInterface> & { message: string }> {
    const url = productsApiUrls.addExtraAttributesToProduct.replace('{attributeId}', attributeId);

    const response = await this.apiClient.request<
      ApiBaseDataResponseObjectType<ApiModelProductAttributeInterface> & { message: string }
    >(ApiHttpMethodEnum.post, url, {
      data: {
        value: payload.value,
        type: payload.type,
        product: payload.product,
        ['type_value']: payload.type_value && Object.keys(payload.type_value).length > 0 ? payload.type_value : null,
      },
    });

    return response.data;
  }

  @Catch()
  public async getOptions(
    filters: ProductsTypesGetOptionsFiltersType,
  ): Promise<
    ApiResponseType<ApiBaseDataResponseObjectType<ApiPaginatedDataInterface<ProductsTypesProductOptionType>>>
  > {
    const params = urlQueryFormat(filters ?? {});

    const response = await this.apiClient.request<
      ApiBaseDataResponseObjectType<ApiPaginatedDataInterface<ProductsTypesProductOptionType>>
    >(ApiHttpMethodEnum.get, productsApiUrls.getAttributes, {
      params,
      cacheable: true,
    });

    return response;
  }

  @Catch()
  public async createOption(data: {
    name: I18nLocalizedStringMapType;
  }): Promise<ApiBaseDataResponseObjectType<ProductsTypesProductOptionType> & { message: string }> {
    const response = await this.apiClient.request<
      ApiBaseDataResponseObjectType<ProductsTypesProductOptionType> & { message: string }
    >(ApiHttpMethodEnum.post, productsApiUrls.options, {
      data,
    });

    return response.data;
  }

  @Catch()
  public async createOptionPreset(
    attributeId: string,
    data: {
      value: I18nLocalizedStringMapType;
      display_order: number;
    },
  ): Promise<ApiBaseDataResponseObjectType<ApiModelProductAttributeInterface>> {
    const url = productsApiUrls.optionPresets.replace('{attributeId}', attributeId);

    const response = await this.apiClient.request<ApiBaseDataResponseObjectType<ApiModelProductAttributeInterface>>(
      ApiHttpMethodEnum.post,
      url,
      {
        data,
      },
    );

    return response.data;
  }

  @Catch()
  public async getOption(
    attributeId: string,
  ): Promise<ApiResponseType<ApiBaseDataResponseObjectType<ProductsTypesProductOptionType>>> {
    const url = productsApiUrls.option.replace('{attributeId}', attributeId);

    const response = await this.apiClient.request<ApiBaseDataResponseObjectType<ProductsTypesProductOptionType>>(
      ApiHttpMethodEnum.get,
      url,
    );

    return response;
  }

  @Catch()
  public async deleteOption(attributeId: string): Promise<ApiBaseMessageResponseObjectType> {
    const url = productsApiUrls.option.replace('{attributeId}', attributeId);

    const response = await this.apiClient.request<ApiBaseMessageResponseObjectType>(ApiHttpMethodEnum.delete, url);

    return response.data;
  }

  @Catch()
  public async updateOption(
    attributeId: string,
    data: { name?: I18nLocalizedStringMapType; display_order?: number },
  ): Promise<ApiBaseMessageResponseObjectType> {
    const url = productsApiUrls.option.replace('{attributeId}', attributeId);

    const response = await this.apiClient.request<ApiBaseMessageResponseObjectType>(ApiHttpMethodEnum.patch, url, {
      data,
    });

    return response.data;
  }

  @Catch()
  public async deleteOptionPreset(attributeId: string, presetId: string): Promise<ApiBaseMessageResponseObjectType> {
    const url = productsApiUrls.optionPreset.replace('{attributeId}', attributeId).replace('{presetId}', presetId);

    const response = await this.apiClient.request<ApiBaseMessageResponseObjectType>(ApiHttpMethodEnum.delete, url);

    return response.data;
  }

  @Catch()
  public async sortProductImage(
    productId: string,
    imageId: string,
    order: number,
  ): Promise<ApiBaseMessageResponseObjectType> {
    const url = productsApiUrls.sortProductImage.replace('{productId}', productId);

    const response = await this.apiClient.request<ApiBaseMessageResponseObjectType>(ApiHttpMethodEnum.post, url, {
      data: {
        'image-id': imageId,
        order,
      },
    });

    return response.data;
  }

  @Catch()
  public async updateProductCustomInputField(
    productId: string,
    payload: ApiModelProductCustomInputInterface,
  ): Promise<ApiBaseDataResponseObjectType<ApiModelProductCustomInputInterface> & { message: string }> {
    const url = productsApiUrls.updateProductCustomInputField.replace('{productId}', productId);

    const data = structuredClone(payload);

    if (data.id.startsWith('new-')) {
      data.id = '';
    }

    const response = await this.apiClient.request<
      ApiBaseDataResponseObjectType<ApiModelProductCustomInputInterface> & { message: string }
    >(ApiHttpMethodEnum.post, url, {
      data: {
        ...data,
        hint: Object.keys(data.hint ?? {}).length === 0 ? i18nGetInitialLocalizedStringMap() : data.hint,
      },
    });

    return response.data;
  }

  @Catch()
  public async updateProductCustomSelectionField(
    productId: string,
    payload: ApiModelProductCustomOptionInterface,
  ): Promise<ApiBaseDataResponseObjectType<ApiModelProductCustomOptionInterface> & { message: string }> {
    const url = productsApiUrls.updateProductCustomSelectionField.replace('{productId}', productId);

    const data = structuredClone(payload);

    if (data.id.startsWith('new-')) {
      data.id = '';
    }

    const response = await this.apiClient.request<
      ApiBaseDataResponseObjectType<ApiModelProductCustomOptionInterface> & { message: string }
    >(ApiHttpMethodEnum.post, url, {
      data: {
        ...data,
        hint: Object.keys(data.hint ?? {}).length === 0 ? i18nGetInitialLocalizedStringMap() : data.hint,
      },
    });

    return response.data;
  }

  @Catch()
  public async deleteProductCustomInputField(
    productId: string,
    inputId: string,
  ): Promise<ApiBaseMessageResponseObjectType> {
    const url = productsApiUrls.deleteProductCustomInputField.replace('{productId}', productId);

    const response = await this.apiClient.request<ApiBaseMessageResponseObjectType>(ApiHttpMethodEnum.post, url, {
      data: {
        'input-id': inputId,
      },
    });

    return response.data;
  }

  @Catch()
  public async deleteProductCustomSelectionField(
    productId: string,
    selectionId: string,
  ): Promise<ApiBaseMessageResponseObjectType> {
    const url = productsApiUrls.deleteProductCustomSelectionField.replace('{productId}', productId);

    const response = await this.apiClient.request<ApiBaseMessageResponseObjectType>(ApiHttpMethodEnum.post, url, {
      data: {
        'selection-id': selectionId,
      },
    });

    return response.data;
  }

  @Catch()
  public async uploadProductDescriptionImage(
    productId: string,
    file: File,
  ): Promise<ApiBaseDataResponseObjectType<ApiModelProductImageInterface> & { message: string }> {
    const url = productsApiUrls.uploadProductDescriptionImage.replace('{productId}', productId);

    const formData = new FormData();
    formData.append('file', file);

    const response = await this.apiClient.request<
      ApiBaseDataResponseObjectType<ApiModelProductImageInterface> & { message: string }
    >(ApiHttpMethodEnum.post, url, {
      data: formData,
    });

    return response.data;
  }

  @Catch()
  public async addVariants(
    productId: string,
    variants: any,
  ): Promise<ApiBaseDataResponseObjectType<ApiModelProductInterface> & { message: string }> {
    const url = productsApiUrls.addProductVariants.replace('{productId}', productId);

    const response = await this.apiClient.request<
      ApiBaseDataResponseObjectType<ApiModelProductInterface> & { message: string }
    >(ApiHttpMethodEnum.post, url, {
      data: {
        variants,
      },
    });

    return response.data;
  }

  public async getProductVouchers(
    productId: string,
    filters: ProductVouchersApiFiltersInterface,
  ): Promise<ApiResponseType<ApiBaseDataResponseObjectType<ApiPaginatedDataInterface<ProductVoucherItemInterface>>>> {
    const url = productsApiUrls.productVouchers.replace('{productId}', productId);

    const response = await this.apiClient.request<
      ApiBaseDataResponseObjectType<ApiPaginatedDataInterface<ProductVoucherItemInterface>>
    >(ApiHttpMethodEnum.get, url, {
      params: filters,
    });

    return response;
  }

  public async importVouchers(
    productId: string,
    file: File,
    requestOptions?: {
      signal?: AbortSignal;
      onUploadProgress?: (progressEvent: ProgressEvent) => void;
    },
  ): Promise<ApiBaseMessageResponseObjectType> {
    const url = productsApiUrls.importVouchers.replace('{productId}', productId);

    const formData = new FormData();
    formData.append('file', file);

    const response = await this.apiClient.request<ApiBaseMessageResponseObjectType>(ApiHttpMethodEnum.post, url, {
      data: formData,
      ...requestOptions,
    });

    return response.data;
  }

  @Catch()
  public async deleteProductVoucher(productId: string, voucherId: string): Promise<ApiBaseMessageResponseObjectType> {
    const url = productsApiUrls.productVoucher.replace('{productId}', productId).replace('{voucherId}', voucherId);

    const response = await this.apiClient.request<ApiBaseMessageResponseObjectType>(ApiHttpMethodEnum.delete, url);

    return response.data;
  }

  @Catch()
  public async updateProductVoucher(
    productId: string,
    voucher: ProductVoucherItemInterface,
  ): Promise<ApiBaseMessageResponseObjectType> {
    const url = productsApiUrls.productVoucher.replace('{productId}', productId).replace('{voucherId}', voucher.id);

    const response = await this.apiClient.request<ApiBaseMessageResponseObjectType>(ApiHttpMethodEnum.put, url, {
      data: voucher,
    });

    return response.data;
  }

  @Catch()
  public async addProductVoucher(
    productId: string,
    voucher: ProductVoucherItemInterface,
  ): Promise<ApiBaseMessageResponseObjectType> {
    const url = productsApiUrls.productVouchers.replace('{productId}', productId);

    const response = await this.apiClient.request<ApiBaseMessageResponseObjectType>(ApiHttpMethodEnum.post, url, {
      data: voucher,
    });

    return response.data;
  }

  @Catch()
  public async getSimilarProducts(
    productId: string,
  ): Promise<ApiBaseDataResponseObjectType<ApiSimilarProductsInterface>> {
    const url = productsApiUrls.similarProducts.replace('{productId}', productId);
    const response = await this.apiClient.request<ApiBaseDataResponseObjectType<ApiSimilarProductsInterface>>(
      ApiHttpMethodEnum.get,
      url,
    );
    return response.data;
  }

  @Catch()
  public async updateSimilarProducts(productId: string, products: any): Promise<ApiBaseDataResponseObjectType> {
    const url = productsApiUrls.updateSimilarProducts.replace('{productId}', productId);
    return this.apiClient
      .request(ApiHttpMethodEnum.post, url, {
        data: { ['products']: products },
      })
      .then((response) => response.data);
  }

  @Catch()
  public async restoreProducts(productIds: string | null): Promise<ApiBaseDataResponseObjectType> {
    const url = productsApiUrls.restoreProducts;
    return this.apiClient
      .request(ApiHttpMethodEnum.patch, url, {
        data: { id: productIds },
      })
      .then((response) => response)
      .catch((error) => error);
  }
  public async generateBarcode(
    data: PrintingBarcodeApiRequestInterface,
  ): Promise<ApiBaseDataResponseObjectType<{ url: string }>> {
    return this.apiClient
      .request<ApiBaseDataResponseObjectType<{ url: string }>>(
        ApiHttpMethodEnum.post,
        productsApiUrls.generateBarcode,
        { data },
      )
      .then((response) => response.data);
  }

  @Catch()
  public async getProductsStockHistory(
    filters: ProductsApiFiltersInterface,
  ): Promise<ApiBaseDataResponseObjectType<ApiPaginatedDataInterface<ApiProductStockHistoryInterface>>> {
    const params = urlQueryFormat(productsApiFiltersToSearchParams(filters));
    const response = await this.apiClient.request<
      ApiBaseDataResponseObjectType<ApiPaginatedDataInterface<ApiProductStockHistoryInterface>>
    >(ApiHttpMethodEnum.get, productsApiUrls.productsQuantityLogs, { params });

    return response.data;
  }

  public async generateS3PresignedUrl(productId: string): Promise<ApiBaseDataResponseObjectType<{ url: string }>> {
    const url = productsApiUrls.generatePresignedUrl.replace('{productId}', productId);

    const response = await this.apiClient.request<ApiBaseDataResponseObjectType<{ url: string }>>(
      ApiHttpMethodEnum.post,
      url,
    );

    return response.data;
  }

  @Catch()
  public async uploadFileToS3(
    presignedUrlData: any,
    file: File,
    options?: {
      onUploadProgress?: (progressEvent: ProgressEvent) => void;
      signal?: AbortSignal; // Add signal property for request cancellation
    },
  ): Promise<void> {
    const { url, fields } = presignedUrlData.response;

    // Ensure fields are defined
    if (!fields || !fields.key || !fields.AWSAccessKeyId || !fields.policy || !fields.signature) {
      throw new Error('Missing necessary fields for S3 upload.');
    }

    const formData = new FormData();
    formData.append('key', fields.key);
    formData.append('AWSAccessKeyId', fields.AWSAccessKeyId);
    formData.append('policy', fields.policy);
    formData.append('signature', fields.signature);
    formData.append('file', file);

    try {
      // Make the POST request to S3 with the onUploadProgress option and support for cancel signal
      await axios.post(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: options?.onUploadProgress, // Track progress
        signal: options?.signal, // Pass abort signal for cancellation
      });
    } catch (error) {
      console.error(error);
    }
  }

  public async getProductDownloadables(
    productId: string,
  ): Promise<ApiBaseDataResponseObjectType<{ results: ProductDownloadableInterface[] }>> {
    const url = productsApiUrls.productDownloadables.replace('{productId}', productId);
    const response = await this.apiClient.request<
      ApiBaseDataResponseObjectType<{ results: ProductDownloadableInterface[] }>
    >(ApiHttpMethodEnum.get, url);
    return response.data;
  }

  public async addProductDownloadable(
    productId: string,
    data:
      | { file: string; display_name: string; save_to_library: boolean }
      | Array<{ file: string; display_name: string }>,
  ): Promise<ApiBaseResponseType> {
    const url = productsApiUrls.productDownloadables.replace('{productId}', productId);

    const payload = data;

    const response = await this.apiClient.request<ApiBaseResponseType>(ApiHttpMethodEnum.post, url, { data: payload });

    return response.data;
  }

  public async deleteProductDownloadable(
    productId: string,
    downloadableId: string,
  ): Promise<ApiBaseResponseObjectType> {
    const url = productsApiUrls.deleteProductDownloadable
      .replace('{productId}', productId)
      .replace('{downloadableId}', downloadableId);
    const response = await this.apiClient.request<ApiBaseResponseObjectType>(ApiHttpMethodEnum.delete, url);
    return response.data;
  }

  public async getDownloadableLibrary(params: {
    display_name?: string;
    ordering?: string;
    page?: number;
    page_size?: number;
  }): Promise<ApiBaseDataResponseObjectType<ApiPaginatedDataInterface<DownloadableLibraryItemInterface>>> {
    const url = productsApiUrls.downloadableLibrary;
    const response = await this.apiClient.request<
      ApiBaseDataResponseObjectType<ApiPaginatedDataInterface<DownloadableLibraryItemInterface>>
    >(ApiHttpMethodEnum.get, url, { params });
    return response.data;
  }

  public async addToDownloadableLibrary(data: { file: string; display_name: string }): Promise<ApiBaseResponseType> {
    const url = productsApiUrls.addDownloadableLibrary;
    const response = await this.apiClient.request<ApiBaseResponseType>(ApiHttpMethodEnum.post, url, { data });
    return response.data;
  }

  public async deleteDownloadableFromLibrary(downloadableId: string): Promise<ApiBaseResponseObjectType> {
    const url = productsApiUrls.deleteDownloadableLibrary.replace('{downloadableId}', downloadableId);
    const response = await this.apiClient.request<ApiBaseResponseObjectType>(ApiHttpMethodEnum.delete, url);
    return response.data;
  }

  public async deleteProductVouchersInBulk(
    productId: string,
    data: string[],
  ): Promise<ApiBaseMessageResponseObjectType> {
    const url = productsApiUrls.vouchersBulkDelete.replace('{productId}', productId);
    const response = await this.apiClient.request<ApiBaseMessageResponseObjectType>(ApiHttpMethodEnum.delete, url, {
      data,
    });

    return response.data;
  }

  public async addVouchersInBluk(
    productId: string,
    data: ProductVoucherItemPayloadInterface[],
  ): Promise<ApiBaseMessageResponseObjectType> {
    const url = productsApiUrls.addBlukVouchers.replace('{productId}', productId);
    const response = await this.apiClient.request<ApiBaseMessageResponseObjectType>(ApiHttpMethodEnum.post, url, {
      data,
    });

    return response.data;
  }

  public async updateVouchersInBluk(
    productId: string,
    data: ProductVoucherItemPayloadInterface[],
  ): Promise<ApiBaseMessageResponseObjectType> {
    const url = productsApiUrls.updateBlukVouchers.replace('{productId}', productId);
    const response = await this.apiClient.request<ApiBaseMessageResponseObjectType>(ApiHttpMethodEnum.patch, url, {
      data,
    });

    return response.data;
  }
}
