import { Route } from 'vue-router';
import { RoutingRouteEnum } from '../route.enum';
import { SubscrbtionEnumPackagesCodeEnum } from '../../subscription/enums/packages-code.enum';
import { userPermissionGuard } from './user-permission.guard';
import { appRouterFactory } from '../factory';

export function isPackageAvailableToCheckout(to: Route, from: Route, next: Function): void {
  userPermissionGuard(
    to,
    (result: any) => {
      if (result === undefined) {
        const queryPackageCode = to.query['package_code']?.toString();

        if (queryPackageCode) {
          const validPrefixes = [
            SubscrbtionEnumPackagesCodeEnum.zidLite,
            SubscrbtionEnumPackagesCodeEnum.newGrowth,
            SubscrbtionEnumPackagesCodeEnum.newProfessional,
          ];
          const isPackageAvailable = validPrefixes.some((prefix) => queryPackageCode.startsWith(prefix));

          if (isPackageAvailable) {
            next();
            return;
          }
          next({ name: RoutingRouteEnum.subscriptionPackages });
          return;
        }

        next();
      } else {
        next(result);
      }
    },
    appRouterFactory(),
  );
}
