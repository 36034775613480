<template>
  <ZidConfirmModalV2
    :class="styles.permission_modal"
    :visible="PermissionDeniedModalModule.isVisible"
    :title="PermissionDeniedModalModule.title ?? $t(I18nMessages['common.permission_required'])"
    @close="onClose"
  >
    <template slot="actions">
      <ZidButton :class="styles.permission_modal__close" @click="onClose">
        {{ $t(I18nMessages['common.close']) }}
      </ZidButton>
    </template>
  </ZidConfirmModalV2>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
import { ZidConfirmModalV2, ZidButton } from '@zidsa/ui';
import useI18nMessages from '../../composables/useI18nMessages';
import { PermissionDeniedModalModule } from '../../../app/store/permission-denied-module';

export default defineComponent({
  components: { ZidConfirmModalV2, ZidButton },
  setup() {
    const I18nMessages = useI18nMessages();

    const onClose = () => {
      PermissionDeniedModalModule.closePermissionDeniedModal();
    };

    return { I18nMessages, PermissionDeniedModalModule, onClose };
  },
});
</script>
<style lang="scss" module="styles">
.permission_modal {
  [class~='zid-confirm-modal-v2__title'] {
    font-size: var(--zid-font-size--base) !important;
    color: var(--zid-colors-neutrals-600) !important;
  }

  &__close {
    margin-inline-end: var(--zid-spacing-0) !important;
  }
}
</style>
