import ApiClient from '../../api/client';
import ApiHttpMethodEnum from '../../api/http-method.enum';
import { ApiBaseDataResponseObjectType, ApiResponseType } from '../../api/types';
import { urlQueryFormat } from '../../common/helpers/url/query/format';
import { TestimoniesResponseInterface } from '../../theme-market/types/testimonies';
import {
  AppMarketUninstallReasonsInterface,
  AppScopeItemInterface,
  SuggestedAppsInterface,
  UninstallationReasonReviewServiceInterface,
} from '../types/app-details/app-details.enum';
import { AppMarketAppParamsInterface, AppMarketAnalyticsParamsInterface } from '../types/app-params.interface';
import {
  AppMarketEmptySearchSuggestedAppsInterface,
  AppMarketTypesAppDetailsInterface,
  AppMarketTypesAppInterface,
  AppMarketTypesAppRateItemInterface,
  AppMarketTypesAppsListInterface,
  AppMarketTypesAppsListPublicInterface,
  AppMarketTypesJSFormTypesPropInterface,
  AppMarketTypesToggleRecurringResponseInterface,
} from '../types/app.interface';
import { AppMarketCategoryInterface } from '../types/category.interface';
import { CollectionInterface, CollectionsResponseInterface } from '../types/collections/collections.type';
import { EmbeddedAppInterface } from '../types/embedded-app.interface';
import { AppMarketHomePageNotifications, AppMarketTypesMyAppResponseInterface } from '../types/my-apps/my-apps.type';
import {
  AppMarketConfirmPurchaseInterface,
  AppMarketTypesPurchasePlanInformationInterface,
  AppMarketTypesPurchasePlanResponseInterface,
} from '../types/purchase-plan.interface';
import { AppMarketSectionsInterface, AppMarketShippingSectionsInterface } from '../types/sections.interface';
import { TechnicalIssueInformationInterface } from '../types/technical-issues.interface';
import { appMarketApiUrls } from './urls';

export class AppMarketApiService {
  constructor(private readonly apiClient: ApiClient) {}

  public async getMyApps(): Promise<ApiBaseDataResponseObjectType<AppMarketTypesMyAppResponseInterface>> {
    return this.apiClient
      .request<ApiBaseDataResponseObjectType<AppMarketTypesMyAppResponseInterface>>(
        ApiHttpMethodEnum.get,
        appMarketApiUrls.myApps,
      )
      .then((response) => response.data);
  }

  public async getHomePageSections(): Promise<AppMarketHomePageNotifications> {
    return this.apiClient
      .request<AppMarketHomePageNotifications>(ApiHttpMethodEnum.get, appMarketApiUrls.homePageSections)
      .then((response) => response.data);
  }

  public async getBannerSection(): Promise<ApiBaseDataResponseObjectType<AppMarketSectionsInterface[]>> {
    return this.apiClient
      .request<any>(ApiHttpMethodEnum.get, appMarketApiUrls.bannerSection)
      .then((response) => response.data);
  }

  public async getSections(): Promise<ApiBaseDataResponseObjectType<AppMarketSectionsInterface[]>> {
    return this.apiClient
      .request<any>(ApiHttpMethodEnum.get, appMarketApiUrls.sections)
      .then((response) => response.data);
  }

  public async getNewestAppsSection(): Promise<ApiBaseDataResponseObjectType<AppMarketSectionsInterface[]>> {
    return this.apiClient
      .request<any>(ApiHttpMethodEnum.get, appMarketApiUrls.newestAppSection)
      .then((response) => response.data);
  }

  public async getShippingSections(
    sectionCategoryId: number,
  ): Promise<ApiBaseDataResponseObjectType<AppMarketShippingSectionsInterface[]>> {
    return this.apiClient
      .request<any>(ApiHttpMethodEnum.get, `${appMarketApiUrls.sections}/${sectionCategoryId}`)
      .then((response) => response.data);
  }

  public async getCategories(): Promise<ApiBaseDataResponseObjectType<AppMarketCategoryInterface[]>> {
    return this.apiClient
      .request<ApiBaseDataResponseObjectType<AppMarketCategoryInterface[]>>(
        ApiHttpMethodEnum.get,
        appMarketApiUrls.categories,
      )
      .then((response) => response.data);
  }

  public async getAppMarketAnalytics(): Promise<AppMarketAnalyticsParamsInterface> {
    return this.apiClient
      .request<AppMarketAnalyticsParamsInterface>(ApiHttpMethodEnum.get, appMarketApiUrls.appMarketAnalytics)
      .then((response) => response.data);
  }

  public async getFeaturedApps(): Promise<ApiBaseDataResponseObjectType<AppMarketTypesAppInterface[]>> {
    return this.apiClient
      .request<ApiBaseDataResponseObjectType<AppMarketTypesAppInterface[]>>(
        ApiHttpMethodEnum.get,
        appMarketApiUrls.featuredApps,
        { cacheable: true },
      )
      .then((response) => response.data);
  }

  public async getApplications(
    queryParams: AppMarketAppParamsInterface,
  ): Promise<ApiBaseDataResponseObjectType<AppMarketTypesAppsListInterface>> {
    const params = urlQueryFormat({
      ...queryParams,
    });
    return this.apiClient
      .request<ApiBaseDataResponseObjectType<AppMarketTypesAppsListPublicInterface>>(
        ApiHttpMethodEnum.get,
        appMarketApiUrls.applications,
        {
          params,
        },
      )
      .then((response) => {
        const apps = response.data.data.applications.map((app) => {
          const parsedApp: AppMarketTypesAppInterface = {
            id: app.id,
            name: app.name,
            icon: app.icon,
            rate: {
              ['avg_rate']: app.rate.avg_rate,
              ['total_rating']: app.rate.total_rating,
            },
            publisher: {
              name: app.developer_name,
            },
            ['short_description']: app.short_description,
            description: '',
            ['is_featured']: false,
            plan: app.plan,
            plan_offer: app.plan_offer,
            plan_discount: app.plan_discount,
            ['developer_name']: app.developer_name,
            categories: app.categories,
            developer: app.developer,
            plan_type: app.plan_type,
          };
          return parsedApp;
        });
        const res: ApiBaseDataResponseObjectType<AppMarketTypesAppsListInterface> = {
          data: {
            applications: apps,
            pagination: response.data.data.pagination,
          },
          status: response.data.status,
        };
        return res;
      });
  }

  public async getEmptyResultsApps(): Promise<AppMarketEmptySearchSuggestedAppsInterface> {
    return this.apiClient
      .request<AppMarketEmptySearchSuggestedAppsInterface>(ApiHttpMethodEnum.get, appMarketApiUrls.packageSuggestedApps)
      .then((response) => response.data);
  }

  public async getApplicationDetails(
    appID: number,
    tapID?: string,
  ): Promise<ApiBaseDataResponseObjectType<AppMarketTypesAppDetailsInterface>> {
    return this.apiClient
      .request<ApiBaseDataResponseObjectType<AppMarketTypesAppDetailsInterface>>(
        ApiHttpMethodEnum.get,
        `${appMarketApiUrls.application}/${appID}${tapID ? `/${tapID}` : ''}`,
      )
      .then((response) => response.data);
  }

  public async getEmbeddedApplicationDetails(
    appID: number,
  ): Promise<ApiBaseDataResponseObjectType<EmbeddedAppInterface>> {
    return this.apiClient
      .request<ApiBaseDataResponseObjectType<EmbeddedAppInterface>>(
        ApiHttpMethodEnum.get,
        `${appMarketApiUrls.embeddedApp}/${appID}`,
      )
      .then((response) => response.data);
  }

  public async togglePinningApp(appID: number): Promise<ApiResponseType> {
    return this.apiClient
      .request<ApiResponseType>(ApiHttpMethodEnum.post, `${appMarketApiUrls.togglePinningApp}/${appID}`)
      .then((response) => response);
  }

  public async getApplicationRates(
    appID: number,
  ): Promise<ApiBaseDataResponseObjectType<AppMarketTypesAppRateItemInterface[]>> {
    return this.apiClient
      .request<ApiBaseDataResponseObjectType<AppMarketTypesAppRateItemInterface[]>>(
        ApiHttpMethodEnum.get,
        `${appMarketApiUrls.applicationRate}/${appID}`,
      )
      .then((response) => response.data);
  }

  public async addApplicationReview(
    appID: number,
    rate: number,
    body: string,
  ): Promise<ApiBaseDataResponseObjectType<AppMarketTypesAppRateItemInterface[]>> {
    return this.apiClient
      .request<ApiBaseDataResponseObjectType<AppMarketTypesAppRateItemInterface[]>>(
        ApiHttpMethodEnum.post,
        `${appMarketApiUrls.applicationRate}/${appID}`,
        {
          data: {
            rate,
            body,
          },
        },
      )
      .then((response) => response.data);
  }

  public async installOAuthApplication(appID: number): Promise<ApiBaseDataResponseObjectType<any>> {
    return this.apiClient
      .request<ApiBaseDataResponseObjectType<any>>(
        ApiHttpMethodEnum.post,
        `${appMarketApiUrls.installApplication}/${appID}`,
      )
      .then((response) => response.data);
  }

  public async getUninstallingReasons(): Promise<AppMarketUninstallReasonsInterface> {
    return this.apiClient
      .request<AppMarketUninstallReasonsInterface>(ApiHttpMethodEnum.get, appMarketApiUrls.uninstalReasons)
      .then((response) => response.data);
  }

  public async getSuggestedApps(appID: number): Promise<SuggestedAppsInterface> {
    return this.apiClient
      .request<SuggestedAppsInterface>(ApiHttpMethodEnum.get, `${appMarketApiUrls.suggestedApps}/${appID}?onlyPaid=1`)
      .then((response) => response.data);
  }

  public async uninstallApplication(
    info: UninstallationReasonReviewServiceInterface,
  ): Promise<ApiBaseDataResponseObjectType> {
    return this.apiClient
      .request<ApiBaseDataResponseObjectType>(
        ApiHttpMethodEnum.post,
        `${appMarketApiUrls.uninstallApplication}/${info.appID}`,
        {
          data: {
            reasons: info.reasonsIDs,
            message: info.message,
          },
        },
      )
      .then((response) => response.data);
  }

  public async sendTechnicalIssueReport(
    info: TechnicalIssueInformationInterface,
  ): Promise<ApiBaseDataResponseObjectType> {
    const data = new FormData();
    if (info.app) data.append('app', info.app);
    if (info.theme_uuid) data.append('theme_uuid', info.theme_uuid);
    if (info.attachments) {
      info.attachments.forEach((attachment, index) => {
        data.append(`attachments[${index}]`, attachment);
      });
    }
    data.append('issue', info.issue);
    data.append('content', info.content);
    return this.apiClient
      .request<ApiBaseDataResponseObjectType>(
        ApiHttpMethodEnum.post,
        `${info.is_app_issue ? appMarketApiUrls.appsTickets : appMarketApiUrls.themesTickets}`,
        {
          data,
        },
      )
      .then((response) => response.data);
  }

  public async installOrEditInstallationJSApplication(
    appID: number,
    // eslint-disable-next-line @typescript-eslint/camelcase
    form_type: AppMarketTypesJSFormTypesPropInterface,
    accountId: string,
  ): Promise<ApiBaseDataResponseObjectType> {
    // eslint-disable-next-line @typescript-eslint/camelcase
    const formType = form_type;
    return this.apiClient
      .request<ApiBaseDataResponseObjectType>(
        ApiHttpMethodEnum.post,
        `${appMarketApiUrls.installApplication}/${appID}?form_type=${formType}`,
        {
          data: {
            ['account_id']: accountId,
          },
        },
      )
      .then((response) => response.data);
  }

  public async subscribeToFreePlan(
    appId: number,
    purchasableId: string,
    activateTrial: boolean,
  ): Promise<ApiBaseDataResponseObjectType<any>> {
    return this.apiClient
      .request<ApiBaseDataResponseObjectType<any>>(ApiHttpMethodEnum.post, appMarketApiUrls.subscribeFreePlan, {
        data: {
          ['app_id']: appId,
          ['purchasable_id']: purchasableId,
          ['activate_trial']: activateTrial,
        },
      })
      .then((response) => response.data);
  }

  public async requestPrivatePlan(appId: number, requestNote: string): Promise<ApiBaseDataResponseObjectType<any>> {
    return this.apiClient
      .request<ApiBaseDataResponseObjectType<any>>(ApiHttpMethodEnum.post, appMarketApiUrls.requestPrivatePlan, {
        data: {
          ['app_id']: appId,
          ['request_note']: requestNote,
        },
      })
      .then((response) => response.data);
  }

  public async toggleAppRecurringSubscription(
    appId: number,
    subscriptionId: number,
    isRecurring: boolean,
  ): Promise<AppMarketTypesToggleRecurringResponseInterface> {
    return this.apiClient
      .request<AppMarketTypesToggleRecurringResponseInterface>(
        ApiHttpMethodEnum.post,
        appMarketApiUrls.toggleRecurringAppSubscription,
        {
          data: {
            ['app_id']: appId,
            ['subscription_id']: subscriptionId,
            ['is_recurring']: isRecurring,
          },
        },
      )
      .then((response) => response.data);
  }

  public async purchasePlan(
    appId: number,
    purchaseData: AppMarketTypesPurchasePlanInformationInterface,
    initiateMode: boolean,
  ): Promise<AppMarketTypesPurchasePlanResponseInterface> {
    return this.apiClient
      .request<ApiBaseDataResponseObjectType<AppMarketTypesPurchasePlanResponseInterface>>(
        ApiHttpMethodEnum.post,
        `${appMarketApiUrls.purchasePlan}/${appId}?response_json=true&response_type=json&initiate_only=${
          initiateMode ? '1' : '0'
        }`,
        {
          data: {
            purchasables: purchaseData.purchasables,
            ['is_recurring']: purchaseData['is_recurring'],
            ...(purchaseData.purchase_id && { purchase_id: purchaseData.purchase_id }),
            ...(purchaseData.coupon_code && { coupon_code: purchaseData.coupon_code }),
          },
        },
      )
      .then((response) => response.data);
  }

  public async confirmPlanPurchase(
    appId: string,
    purchaseId: string,
    zidService: string,
    queryString: string,
  ): Promise<AppMarketConfirmPurchaseInterface> {
    return this.apiClient
      .request<AppMarketConfirmPurchaseInterface>(
        ApiHttpMethodEnum.get,
        `${appMarketApiUrls.confirmPlanPurchase}/${appId}/${purchaseId}/${zidService}?${queryString}`,
      )
      .then((response) => response.data);
  }

  public async embeddedCardCheckout(data: { purchaseId: string; appId: number; card_source: string }): Promise<any> {
    return this.apiClient
      .request<any>(
        ApiHttpMethodEnum.post,
        `${appMarketApiUrls.purchaseWithEmbeddedCardCheckout}/${data.purchaseId}/checkout`,
        { data: { app_id: data.appId, card_source: data.card_source } },
      )
      .then((response) => response.data);
  }

  public async getAppMarketTestimonies(): Promise<TestimoniesResponseInterface> {
    return this.apiClient
      .request<TestimoniesResponseInterface>(ApiHttpMethodEnum.get, `${appMarketApiUrls.getTestimonies}?market=apps`)
      .then((response) => response.data);
  }

  public async getAppMarketCollections(): Promise<CollectionsResponseInterface> {
    return this.apiClient
      .request<CollectionsResponseInterface>(ApiHttpMethodEnum.get, `${appMarketApiUrls.getCollections}`)
      .then((response) => response.data);
  }

  public async getAppMarketCollection(collectionID: number): Promise<CollectionInterface> {
    return this.apiClient
      .request<CollectionInterface>(ApiHttpMethodEnum.get, `${appMarketApiUrls.getCollections}/${collectionID}`)
      .then((response) => response.data);
  }

  public async getScopesGroups(appID: number): Promise<ApiBaseDataResponseObjectType<AppScopeItemInterface[]>> {
    return this.apiClient
      .request<ApiBaseDataResponseObjectType<AppScopeItemInterface[]>>(
        ApiHttpMethodEnum.get,
        `${appMarketApiUrls.grantedScopesGroups}/${appID}/granted-scopes-groups`,
      )
      .then((response) => response.data);
  }

  public async activateOAuthApp(appID: number): Promise<ApiBaseDataResponseObjectType> {
    return this.apiClient
      .request<ApiBaseDataResponseObjectType>(
        ApiHttpMethodEnum.post,
        `${appMarketApiUrls.activateOAuthApp}/${appID}/oauth-activate`,
      )
      .then((response) => response.data);
  }
}
