import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import AppStoreReadyStateEnum from '../../app/store/ready-state.enum';
import UserApiServiceFactory from '../api/service-factory';
import ApiModelUserInterface from '../../api/model/user.interface';
import appStoreFactory from '../../app/store/factory';

const userService = UserApiServiceFactory();

@Module({
  dynamic: true,
  name: 'user',
  store: appStoreFactory(),
  namespaced: true,
})
class UserStoreModule extends VuexModule {
  public loadingState: AppStoreReadyStateEnum = AppStoreReadyStateEnum.pending;
  public logoutState: AppStoreReadyStateEnum = AppStoreReadyStateEnum.pending;
  public data: ApiModelUserInterface | null = null;
  public error: Error | null = null;

  @Mutation
  private FETCH() {
    this.loadingState = AppStoreReadyStateEnum.loading;
    this.error = null;
  }

  @Mutation
  private FETCH_SUCCESS(data: ApiModelUserInterface) {
    this.loadingState = AppStoreReadyStateEnum.loaded;
    this.data = data;
  }

  @Mutation
  private FETCH_ERROR(error: Error): void {
    this.loadingState = AppStoreReadyStateEnum.error;
    this.error = error;
  }

  @Mutation
  private UPDATE_GOOGLE_MAP_ENABLED(value: boolean) {
    if (this.data?.store) {
      this.data.store['is_gmaps_in_address_enabled'] = value;
    }
  }

  @Mutation
  private HYDRATE(data: ApiModelUserInterface) {
    this.loadingState = AppStoreReadyStateEnum.loaded;
    this.error = null;
    this.data = data;
  }

  @Mutation
  private SET_LOGOUT_STATE(): void {
    this.logoutState = AppStoreReadyStateEnum.loading;
  }

  @Mutation
  private LOGOUT_SUCCESS() {
    this.loadingState = AppStoreReadyStateEnum.loaded;
    this.logoutState = AppStoreReadyStateEnum.loaded;
    this.error = null;
  }

  @Action
  public async fetch() {
    this.FETCH();
    try {
      const userDetails = await userService.getUserDetails();

      this.FETCH_SUCCESS(userDetails.data);
    } catch (error) {
      this.FETCH_ERROR(error as Error);
    }
  }

  @Action
  public hydrate(user: ApiModelUserInterface): void {
    this.HYDRATE(user);
  }

  @Action updateGoogleMapSetting(value: boolean): void {
    this.UPDATE_GOOGLE_MAP_ENABLED(value);
  }

  @Action
  public logout() {
    this.FETCH();
    this.SET_LOGOUT_STATE();

    userService
      .logout()
      .then(() => {
        this.LOGOUT_SUCCESS();
        sessionStorage.removeItem('zid_can_user_see_category_selection_modal');
        window.location.replace('/login');
      })
      .catch((err: Error) => {
        this.FETCH_ERROR(err);
      });
  }

  @Action
  public async sendVerificationMerchantMailLink() {
    try {
      const response = await userService.sendVerificationMerchantMailLink();

      return response;
    } catch (error) {}
  }
}

export default getModule(UserStoreModule);
