import type { AxiosError } from 'axios';
import appI18nFactory from '../../app/i18n/factory';
import NotificationTypeEnum from '../../notifications/components/type.enum';
import showNotification from '../helpers/show-notification/show-notification';

interface OptionsInterface {
  errorHandler?: (error: any, ctx: any) => any;
  onError?: (error: any, ctx: any) => any;
  identifier?: string;
}

interface Validations {
  response: string;
  ['validation_errors']: Array<{
    detail: Array<string>;
  }>;
}

export function getServerErrorMessage(error: AxiosError): string {
  if (error.code === 'ERR_CANCELED') {
    const i18n = appI18nFactory();
    return i18n.messages[i18n.locale]['common.request.canceled'].toString();
  }

  let message = error.message;

  if (error.response && error.response.data) {
    message = (error.response.data as any).message;

    const validations = (error.response.data as any).validations;

    if (validations && validations.length) {
      if (typeof validations[0].errors === 'string') message = validations[0].errors;
      else if (Array.isArray(validations[0].errors)) message = validations[0].errors[0];
      else if (typeof validations?.[0] === 'object') {
        const values = Object.values(validations?.[0]);
        if (typeof values[0] === 'string') {
          message = values[0];
        } else if (Array.isArray(values[0])) {
          message = values[0][0];
        }
      } else message = validations[0].errors[0];
    }

    if (validations && typeof validations === 'object' && !Array.isArray(validations)) {
      const entries = Object.values(validations);
      if (typeof entries[0] === 'string') {
        message = entries[0];
      } else if (Array.isArray(entries[0])) {
        message = entries[0][0];
      }
    }
  }

  return message;
}

function displayExtentionBackendErrorMessages(validations: Validations, identifier: string | undefined): void {
  validations['validation_errors'].forEach((error) => {
    const message = error.detail.join(' ');
    showNotification(message, NotificationTypeEnum.danger, identifier);
  });
}

function handleErrorNotification(error: AxiosError, identifier: string | undefined): void {
  const validations: Validations | null = error.response ? (error.response.data as any).validations : null;
  if (
    validations &&
    !Array.isArray(validations) &&
    validations?.['validation_errors'] &&
    validations?.['validation_errors'].length > 0
  ) {
    displayExtentionBackendErrorMessages(validations, identifier);
  } else {
    const message = getServerErrorMessage(error as AxiosError);

    if (message.length) showNotification(message, NotificationTypeEnum.danger, identifier);
  }
}

function Catch(options?: OptionsInterface) {
  return function (target: any, propertyKey: string, descriptor: PropertyDescriptor): any {
    const originalMethod = descriptor.value;

    descriptor.value = async function (...args: any[]): Promise<void> {
      try {
        return await originalMethod.apply(this, args);
      } catch (error) {
        if (options?.errorHandler) {
          // To define your custom error handler
          options?.errorHandler.call(null, error, this);
        } else {
          // By default we take the error and show it in a toast/notification
          const message = getServerErrorMessage(error as AxiosError);

          if (message.length) handleErrorNotification(error as AxiosError, options?.identifier);
        }
        /*
         * Something you want to do in your decorated method after the error has been catched
         * (e.g: Reset any loading status)
         */
        options?.onError?.call(null, error, this);
      }
    };

    return descriptor;
  };
}

export default Catch;
