import {
  ZidBox,
  ZidButton,
  ZidHeading,
  ZidIcon,
  ZidLoader,
  ZidTable,
  ZidTableCell,
  ZidTableRow,
  ZidTableRowGroup,
  ZidText,
  ZidTooltip,
} from '@zidsa/ui';
import { CreateElement, VNode } from 'vue';
import { Component, Mixins, Prop } from 'vue-property-decorator';

import styles from '../WalletInfoTables.module.scss';
import { ZidWalletStoreModule } from '../../../store/wallet.module';
import { AuthUserStoreModule } from '../../../../account/store/user.module';
import { I18nMessages } from '../../../../i18n/messages';
import { ZidWalletVerificationStatus } from '../../../types/Wallet/wallet';
import AppStoreReadyStateEnum from '../../../../app/store/ready-state.enum';
import { ZidWalletModalsStoreModule } from '../../../store/modal-booleans.module';
import { AppStoreModule } from '../../../../app/store/module';
import { ZidWalletPermissionsMixin } from '../../../mixins/wallet-permissions.mixin';
import { PermissionDeniedModalModule } from '../../../../app/store/permission-denied-module';

@Component
export class WalletComponentsAccountBankInfoComponent extends Mixins(ZidWalletPermissionsMixin) {
  @Prop()
  private readonly isModal!: boolean;

  private copiedText = '';

  private get bankHolderName(): string | undefined {
    return ZidWalletStoreModule.data?.bankAccountHolderName;
  }

  private get bankIban(): string | undefined {
    return ZidWalletStoreModule.data?.bankAccountNumber;
  }

  private get isWalletVerified(): boolean {
    return ZidWalletStoreModule.data.isVerified;
  }

  private get isWalletVerificationPending(): boolean {
    return ZidWalletStoreModule.data.verificationStatus === ZidWalletVerificationStatus.REVIEW_PENDING;
  }

  private get isLoading(): boolean {
    return [AppStoreReadyStateEnum.loading, AppStoreReadyStateEnum.pending].includes(ZidWalletStoreModule.loadingState);
  }

  async created(): Promise<void> {
    await AuthUserStoreModule.fetch();
  }

  private renderCopyIcon(h: CreateElement, text: string): VNode {
    const copied = (
      <span class={styles.bank_transfer_modal__copied_text}>
        <ZidIcon icon={'check_mark'} size={'xxs'} color={'success'} />
      </span>
    );
    const copyIcon = <ZidIcon icon={'copy'} size={'xs'} onClick={(): void => this.onClickCopy(text)} />;
    return this.copiedText.includes(text) ? copied : copyIcon;
  }

  render(h: CreateElement): VNode {
    return (
      <div class={{ [styles.wallet__parent]: true, [styles['wallet__parent--loader']]: this.isLoading }}>
        {this.isLoading ? (
          <ZidLoader />
        ) : this.isWalletVerified ? (
          <div class={styles.wallet__parent}>{this.renderBankAcccountInfo(h)}</div>
        ) : (
          <div class={styles.wallet__parent}>{this.renderUnverifiedBankComponent(h)}</div>
        )}
      </div>
    );
  }

  private renderUnverifiedBankComponent(h: CreateElement): VNode {
    return (
      <div class={styles['wallet__unverified']}>
        <ZidBox class={styles['wallet__unverified--verify']}>
          {this.isWalletVerificationPending ? (
            <div>
              <ZidText size='small' weight='semibold' type='primary'>
                {this.$t(I18nMessages['wallet.under_review.description'])}
              </ZidText>
            </div>
          ) : (
            <div>
              <span>
                <ZidButton link onClick={this.onToggleVerifyWalletModal} style={'display: inline-block'}>
                  {this.$t(I18nMessages['wallet.verify_account'])}
                </ZidButton>
              </span>
              <span>
                <ZidText>{this.$t(I18nMessages['wallet.verify_account.to'])}</ZidText>
              </span>
            </div>
          )}
        </ZidBox>
      </div>
    );
  }

  private renderBankAcccountInfo(h: CreateElement): VNode {
    return (
      <div class={styles['wallet__bank-table--column']}>
        <ZidHeading level={6} weight={'semibold'} class={styles['wallet__tooltip']}>
          {this.$t(I18nMessages['wallet.store_owner_information'])}

          <ZidTooltip class={styles.inventory__container__info_sub_title_tooltip} contentWidth='200px'>
            <ZidIcon size={'xxs'} color={'var(--zid-colors-neutrals-400)'} icon='danger_circle_outline' />
            <template slot='tooltip'>{this.$t(I18nMessages['wallet.store_owner_information.tooltip'])}</template>
          </ZidTooltip>
        </ZidHeading>
        {this.isModal && (
          <ZidText class={styles['wallet__bank-table--owner']}>
            {this.$t(I18nMessages['wallet.topup.owner_account.tip'])}
          </ZidText>
        )}
        <ZidTable wrapper={false}>
          <ZidTableRowGroup>
            <ZidTableRow>
              <ZidTableCell>
                <ZidText>{this.$t(I18nMessages['zidship.wallet.account_holder_name'])}</ZidText>
              </ZidTableCell>
              <ZidTableCell>
                <ZidText weight={'semibold'}>{this.bankHolderName} </ZidText>
              </ZidTableCell>
            </ZidTableRow>
          </ZidTableRowGroup>
          <ZidTableRowGroup>
            <ZidTableCell>
              <ZidText>{this.$t(I18nMessages['zidship.wallet.bank_name'])}</ZidText>
            </ZidTableCell>
            <ZidTableCell>
              <ZidText
                weight={'semibold'}
                class={{
                  [styles['wallet__bank-table--owner--with-sidebar']]: !AppStoreModule.settings?.navbarCollapsed,
                  [styles['wallet__bank-table--owner--without-sidebar']]: AppStoreModule.settings?.navbarCollapsed,
                }}
              >
                {this.$t(
                  I18nMessages[`wallet.bic_bank.${ZidWalletStoreModule.data?.bankBic}` as keyof typeof I18nMessages],
                )}
              </ZidText>
            </ZidTableCell>
          </ZidTableRowGroup>
          <ZidTableRowGroup>
            <ZidTableCell>
              <ZidText>{this.$t(I18nMessages['zidship.wallet.iban'])}</ZidText>
            </ZidTableCell>
            <ZidTableCell>
              {this.bankIban && (
                <div
                  class={{
                    [styles['wallet__bank-table--copy']]: true,
                  }}
                >
                  {this.renderCopyIcon(h, this.bankIban)}
                  <ZidText type={'primary'} weight={'semibold'}>
                    {this.bankIban}
                  </ZidText>
                </div>
              )}
            </ZidTableCell>
          </ZidTableRowGroup>
        </ZidTable>
      </div>
    );
  }

  private onClickCopy(text: string): void {
    navigator.clipboard.writeText(text);
    this.copiedText = text;
  }

  private onToggleVerifyWalletModal(): void {
    if (!this.hasManageWalletPermission) {
      PermissionDeniedModalModule.showPermissionDeniedModal();
      return;
    }

    ZidWalletModalsStoreModule.toggleVerificationModal();
  }
}
