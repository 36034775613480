import { Route } from 'vue-router';
import { RoutingRouteEnum } from '../route.enum';
import UserStoreModule from '../../user/store/module';
import { userPermissionGuard } from './user-permission.guard';
import { appRouterFactory } from '../factory';

export function subscriptionWebManagerGuard(to: Route, from: Route, next: Function): void {
  const user = UserStoreModule.data;
  const isWebSubscription = user?.store.subscription.manager?.web ?? true;

  userPermissionGuard(
    to,
    (result: any) => {
      if (result === undefined) {
        if (isWebSubscription) {
          next();
        } else {
          next({ name: RoutingRouteEnum.subscriptionsIndex });
        }
      } else {
        next(result);
      }
    },
    appRouterFactory(),
  );
}
