<template>
  <svg
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    viewBox="200 200 150 170"
    :width="width"
    :height="height"
  >
    <path
      d="M318.9,283.5c0,10.5,5.7,19.6,14.2,24.5v3.8h-14.2v28.3c15.7,0,28.3-12.7,28.3-28.3v-56.7h-28.3v28.3Z"
      class="st0"
    ></path>
    <path
      d="M233.9,255.1c0,10.5,5.7,19.6,14.2,24.5v3.8h-28.3v28.3h14.2c15.7,0,28.3-12.7,28.3-28.3v-28.3h-28.3Z"
      class="st0"
    ></path>
    <rect x="318.9" y="226.8" width="28.3" height="14.2" class="st0"></rect>
    <path
      d="M276.4,226.8c0,10.5,5.7,19.6,14.2,24.5v3.8h-14.2v56.7h0c15.7,0,28.3-12.7,28.3-28.3v-56.7h-28.3Z"
      class="st0"
    ></path>
  </svg>
</template>
<script>
import { defineComponent, toRefs } from 'vue';
export default defineComponent({
  props: {
    width: {
      default: '1046',
      type: String,
    },
    height: {
      default: '782',
      type: String,
    },
  },

  setup(props) {
    const { width, height } = toRefs(props);

    return { width, height };
  },
});
</script>
<style lang="scss" scoped>
path {
  fill: #27574f;
  stroke-width: 0px;
}
</style>
