export interface Option {
  value: number | string;
  label: string;
}

export interface Question {
  code: PresetupQuestionCodeEnum;
  title: string;
  options: Array<Option>;
  answer?: Option | Option[];
  isMultiSelect?: boolean;
  previous?: Question;
}

export enum OtpMethodEnum {
  mobile = 'mobile',
  whatsapp = 'whatsapp',
}

export const enum PresetupQuestionCodeEnum {
  'has_experience_with_commerce' = 'has_experience_with_commerce',
  'physical_or_digital_products' = 'physical_or_digital_products',
  'sale_channels' = 'sale_channels',
  'zid_support_for_merchant' = 'zid_support_for_merchant',
  'monthly_sales' = 'monthly_sales',
  'migration_needed' = 'migration_needed',
  'current_platform' = 'current_platform',
  'merchant_description' = 'merchant_description',
  'before_zid' = 'before_zid',
  'is_first_store' = 'is_first_store',
  'migration_from' = 'migration_from',
  'migration_to_zid' = 'migration_to_zid',
  'previous_platform' = 'previous_platform',
  'had_store_in_zid_before' = 'had_store_in_zid_before',
  'schedule_agent_call' = 'schedule_agent_call',
  'store_launch_timeframe' = 'store_launch_timeframe',
  'schedule_agent_call_for_switching' = 'schedule_agent_call_for_switching',
}
