import { UserPermissionSlugEnum } from './../../api/model/user/permission-slug.enum';
import VueRouter, { Route } from 'vue-router';
import { userStoreHasPermission } from '../../user/store/has-permission';
import { RoutingRouteEnum } from '../route.enum';
import { PermissionDeniedModalModule } from '../../app/store/permission-denied-module';
import { UnrestrictedPages } from '../../api/model/user/unrestricted-pages';

export function userPermissionGuard(to: Route, next: Function, router: VueRouter): void {
  const routeNameAsSlugKey = to.name as keyof typeof UserPermissionSlugEnum;
  const isLoginOrRegister = window.__INITIAL_STATE__.isLoginOrRegister;
  const requiredPermissionSlug = UserPermissionSlugEnum[routeNameAsSlugKey];
  if (
    (routeNameAsSlugKey && userStoreHasPermission(requiredPermissionSlug)) ||
    UnrestrictedPages.includes(to.name as RoutingRouteEnum) ||
    isLoginOrRegister
  ) {
    next();
  } else {
    PermissionDeniedModalModule.showPermissionDeniedModal();
    next(false);
  }
}
